<template>
    <div>
        <div id="app">
            <router-view />
        </div>
    </div>

</template>
<script>
    export default {
        name: 'app',
    }
</script>
<style lang="less" scoped>
    /*#app {*/
    /*    //主要内容*/
    /*    .content-box {*/
    /*        min-height: 700px;*/
    /*        padding: 10px;*/
    /*    }*/
    /*}*/
</style>
