// 定义全局axios拦截器
import axios from "axios"
import router from "./index";
import store from "@/store/index";
import { Message,Loading } from 'element-ui';
//默认发送到哪个地址以及端口
// axios 配置
// axios.defaults.baseURL = 'http://127.0.0.1:9999';
axios.defaults.baseURL = 'http://sever.48video.cn:4443/api';


/**
 * 调用一次startLoadingAddCount() 方法 开启Loading 并 loadingCount + 1
 * 调用一次endLoadingSubCount() 方法 loadingCount - 1 直到为0 关闭loading
 */
export function startLoadingAddCount() {
    if ( store.state.requestCount=== 0) {
        store.commit("set_loading",true)
        // startLoading()
    }
    store.commit("set_requestCount",1)
}

export function endLoadingSubCount() {
    store.commit("remove_requestCount",1)
    if (store.state.requestCount === 0) {
        store.commit("set_loading",false)
    }
}

const request = axios.create({
    timeout: 3600000,  //超时时间60分钟
    headers: {
        "Content-Type": "application/json;charset=utf-8"   //返回类型json 以及数据格式
    }
})


//拦截请求，将localStorage中的token值取出赋给headers 的Authorization
request.interceptors.request.use(config => {
    // 请求显示loading 效果
    startLoadingAddCount();
    // if (localStorage.token) { //判断token是否存在
    //     config.headers.Authorization = localStorage.token;  //将token设置成请求头
    // }
    return config;
}, error => {
    // 对请求错误做些什么
    console.log(error)
    endLoadingSubCount();
})

//拦截响应
request.interceptors.response.use(resp=>{

    endLoadingSubCount();
    // let res = resp.data;
    // if (res.Code===200){
        return resp;
    // }else{
    //     Element.Message.error(res.msg?'系统异常':res.msg)
    //     return Promise.reject(resp.data.msg)
    // }
},error => {
    endLoadingSubCount();
    // if (error.response.data){
    //     error.massage=error.response.data.massage
    // }
    // if (error.response.status===401){
    //     router.push("/login") //没有权限，跳转到login页面
    // }
    // 弹窗error信息，时间为3秒
    Element.Message.error(error.massage,{duration:3000})

    // return Promise.reject(error)
})


export default request;
