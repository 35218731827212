import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        requestCount: 0,
        token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
        // 电视剧类型配置
        typeConfigDataList: [],
        // 免校验路径集合
        noCheckPathList: ['superIndex', 'index', 'film', 'teleplay', 'Adhibition', 'teleplayDetailView', 'video_introduce', 'filmDetailView', 'film_introduce', 'teleplay_introduce', 'login', 'performInPubilc', 'pocket', 'sports'],
        // 地区配置
        PlaceConfigList: [],
        SportsPlaceConfigDataList: [],
        PocketPlaceConfigDataList: [],
        SportsTypeConfigDataList: [],
        performInPubilcPlaceConfigDataList: [],
        performInPubilcTypeConfigDataList: [],
        PocketTypeConfigDataList: [],
        ZZ_TOKEN: localStorage.getItem('ZZ_TOKEN') ? JSON.parse(localStorage.getItem('ZZ_TOKEN')) : '',
        menuList: [
            {
                name: 'safeManager',
                title: '视频管理',
                icon: 'el-icon-video-camera-solid',
                children: [
                    {
                        path: '/filmManagement',
                        name: 'filmManagement',
                        title: '电影管理',
                        icon: 'el-icon-video-camera-solid',
                    }, {
                        path: '/teleplayManagement',
                        name: 'teleplayManagement',
                        title: '电视剧管理',
                        icon: 'el-icon-video-camera-solid',
                    },{
                        path: '/performInPubilcManagement',
                        name: 'performInPubilcManagement',
                        title: '公演视频管理',
                        icon: 'el-icon-video-camera-solid',
                    },{
                        path: '/pocketManagement',
                        name: 'pocketManagement',
                        title: '口袋视频管理',
                        icon: 'el-icon-video-camera-solid',
                    },{
                        path: '/sportsManagement',
                        name: 'sportsManagement',
                        title: '体育视频管理',
                        icon: 'el-icon-video-camera-solid',
                    },
                ]
            },
            {
                name: 'storageManagement',
                title: '存储管理',
                icon: 'el-icon-s-tools',
                children: [
                    {
                        path: '/storageFileManagement',
                        name: 'storageFileManagement',
                        title: 'MP4存储文件管理',
                        icon: 'el-icon-s-flag',
                    }
                ]
            },
            {
                name: 'placeManagement',
                title: '系统信息配置',
                icon: 'el-icon-s-tools',
                children: [
                    {
                        path: '/sportsPlaceConfigManagement',
                        name: 'sportsPlaceConfigManagement',
                        title: '体育地区参数配置',
                        icon: 'el-icon-s-flag',
                    },
                    {
                        path: '/sportsTypeConfigManagement',
                        name: 'sportsTypeConfigManagement',
                        title: '体育类型参数配置',
                        icon: 'el-icon-s-grid',
                    },
                    {
                        path: '/pocketPlaceConfigManagement',
                        name: 'pocketPlaceConfigManagement',
                        title: '口袋地区参数配置',
                        icon: 'el-icon-s-flag',
                    },
                    {
                        path: '/performInPubilcTypeConfigManagement',
                        name: 'performInPubilcTypeConfigManagement',
                        title: '公演类型参数配置',
                        icon: 'el-icon-s-grid',
                    },
                    {
                        path: '/performInPubilcPlaceConfigManagement',
                        name: 'performInPubilcPlaceConfigManagement',
                        title: '公演地区参数配置',
                        icon: 'el-icon-s-flag',
                    },
                    {
                        path: '/pocketTypeConfigManagement',
                        name: 'pocketTypeConfigManagement',
                        title: '口袋类型参数配置',
                        icon: 'el-icon-s-grid',
                    },
                    {
                        path: '/typeConfigManagement',
                        name: 'typeConfigManagement',
                        title: '影视类型参数配置',
                        icon: 'el-icon-s-grid',
                    },
                    {
                        path: '/placeConfigManagement',
                        name: 'placeConfigManagement',
                        title: '影视地区参数配置',
                        icon: 'el-icon-s-flag',
                    },
                ]
            }
        ],//菜单栏路由
        authoritys: [], //用户权限
        //判断是否已经请求刷新菜单路由和权限    （重新登录可以解决，路由解决问题）
        hasRoute: localStorage.getItem('hasRoute') ? localStorage.getItem('hasRoute') : false,
        editableTabsValue: localStorage.getItem('editableTabsValue') ? JSON.parse(localStorage.getItem('editableTabsValue')) : 'Index',
        editableTabs: localStorage.getItem('editableTabs') ? JSON.parse(localStorage.getItem('editableTabs')) : [{
            title: '首页',
            name: 'safeManager',
        }],
    },
    getters: {},
    mutations: {
        //放置方法的地方一般就是set方法，向state中的变量放置值
        //清除用户信息
        resetState: (state) => {
            state.ZZ_TOKEN = ''
            state.menuList = []
            state.authoritys = []
            state.hasRoute = false
            state.falseeditableTabsValue = 'safeManager'
            state.editableTabs = [{
                title: '首页',
                name: 'safeManager',
            }]
        },
        //设置用户的菜单路由信息
        setMenuList(state, menus) {
            state.menuList = menus
        },
        //设置用户的权限信息
        setAuthoritys(state, menus) {
            state.authoritys = menus
        },
        //设置hasRoute为true
        changeRouteStatus(state, hasRoute) {
            state.hasRoute = hasRoute
            localStorage.setItem("hasRoute", hasRoute)
        },
        // 设置选中的内容
        setEditableTabsValue(state, EditableTabName) {
            state.editableTabsValue = EditableTabName
        },
        //添加tabs标签页的方法
        addTabs(state, tab) {
            //查找一下索引是否已经存在，没有查到则为-1
            let index = state.editableTabs.findIndex(e => e.name === tab.name && e.title === tab.title)
            if (index === -1) {
                state.editableTabs.push({
                    title: tab.title,
                    name: tab.name,
                });
            }
            state.editableTabsValue = tab.name;
            localStorage.setItem('editableTabsValue', JSON.stringify(tab.name))
            localStorage.setItem('editableTabs', JSON.stringify(state.editableTabs))
        },
        set_requestCount: (state, requestCount) => {
            state.requestCount += requestCount
        },
        set_typeConfigDataList: (state, typeConfigDataList) => {
            state.typeConfigDataList = typeConfigDataList
        },
        set_pllaceConfigList: (state, PlaceConfigList) => {
            state.PlaceConfigList = PlaceConfigList
        },
        set_SportsTypeConfigDataList: (state, SportsTypeConfigDataList) => {
            state.SportsTypeConfigDataList = SportsTypeConfigDataList
        },
        set_SportsPlaceConfigDataList: (state, SportsPlaceConfigDataList) => {
            state.SportsPlaceConfigDataList = SportsPlaceConfigDataList
        },
        remove_SportsTypeConfigDataList: (state, SportsTypeConfigDataList) => {
            state.SportsTypeConfigDataList = []
        },
        remove_SportsPlaceConfigDataList: (state, SportsPlaceConfigDataList) => {
            state.SportsPlaceConfigDataList = []
        },
        set_PocketTypeConfigDataList: (state, PocketTypeConfigDataList) => {
            state.PocketTypeConfigDataList = PocketTypeConfigDataList
        },
        set_PocketPlaceConfigDataList: (state, PocketPlaceConfigDataList) => {
            state.PocketPlaceConfigDataList = PocketPlaceConfigDataList
        },
        set_performInPubilcPlaceConfigDataList: (state, performInPubilcPlaceConfigDataList) => {
            state.performInPubilcPlaceConfigDataList = performInPubilcPlaceConfigDataList
        },
        set_performInPubilcTypeConfigDataList: (state, performInPubilcTypeConfigDataList) => {
            state.performInPubilcTypeConfigDataList = performInPubilcTypeConfigDataList
        },
        remove_PocketTypeConfigDataList: (state, PocketTypeConfigDataList) => {
            state.PocketTypeConfigDataList = []
        },
        remove_performInPubilcPlaceConfigDataList: (state, performInPubilcPlaceConfigDataList) => {
            state.performInPubilcPlaceConfigDataList = []
        },
        remove_performInPubilcTypeConfigDataList: (state, performInPubilcTypeConfigDataList) => {
            state.performInPubilcTypeConfigDataList = []
        },
        remove_PocketPlaceConfigDataList: (state, PocketPlaceConfigDataList) => {
            state.PocketPlaceConfigDataList = []
        },
        remove_typeConfigDataList: (state, typeConfigDataList) => {
            state.typeConfigDataList = []
        },
        remove_pllaceConfigList: (state, PlaceConfigList) => {
            state.PlaceConfigList = []
        },
        set_loading: (state, loading) => {
            state.loading = loading
        },
        remove_requestCount: (state, requestCount) => {
            state.requestCount -= requestCount
        },
        //将token注入到state中
        set_token: (state, token) => {
            state.token = token
            localStorage.setItem('token', token)
        },
        // 删除本地存储的token
        remove_token: (state) => {
            state.token = ''
        },
    },
    actions: {},
    modules: {}
})
