import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入axios以及VueAxios
// import Axios from 'axios'
// import VueAxios from 'vue-axios'
import './static/css/gloab.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入富文本vue-quill-editor，全局配置
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// // 引入高德地图组件
// import VueAMap from 'vue-amap'
//
// // 高德地图配置key
// VueAMap.initAMapApiLoader({
// // 修改下面的key值
//   key: '1a68a11fc1ceb6fd0dae634549a98099',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   // 默认高德 sdk 版本为 1.4.4
//   v: '1.4.4'
// })
//引入视频相关
import "video.js/dist/video-js.css";
//在main.js中全局引入axios
import axios from './router/axios'
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.use(VueAxios,Axios)
Vue.use(VueQuillEditor)
// Vue.use(VueAMap)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
