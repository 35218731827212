import Vue from 'vue'
import VueRouter from 'vue-router'
// import index from '../views/index.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    // 首页
    {
        path: '/',
        name: 'superIndex',
        component: () => import('@/views/superIndex'),
        children: [
            {
                path: '/',
                name: 'index',
                component: () => import('@/views/index'),
            },  // 电影页面
            {
                path: '/film',
                name: 'film',
                component: () => import('@/views/film/film')
            },
            // 电视剧页面
            {
                path: '/teleplay',
                name: 'teleplay',
                component: () => import('@/views/teleplay/teleplay')
            },
            {
                path: '/teleplayDetailView',
                name: 'teleplayDetailView',
                component: () => import( '@/views/subViews/teleplayDetailView')
            },
            {
                path: '/filmDetailView',
                name: 'filmDetailView',
                component: () => import( '@/views/subViews/filmDetailView')
            },
            {
                path: '/film_introduce',
                name: 'film_introduce',
                component: () => import( '@/views/subViews/film_introduce')
            },
            {
                path: '/teleplay_introduce',
                name: 'teleplay_introduce',
                component: () => import( '@/views/subViews/teleplay_introduce')
            },
            {
                path: '/video_introduce',
                name: 'video_introduce',
                component: () => import( '@/views/subViews/video_introduce')
            },
            {
                path: '/performInPubilc',
                name: 'performInPubilc',
                component: () => import( '@/views/performInPubilc/performInPubilc')
            },
            {
                path: '/pocket',
                name: 'pocket',
                component: () => import( '@/views/pocket/pocket')
            },
            {
                path: '/sports',
                name: 'sports',
                component: () => import( '@/views/sports/sports')
            },
        ]
    },
    {
        path: '/safeManager',
        name: 'safeManager',
        component: () => import('@/views/safeManager/safeManager'),
        children: [
            {
                path: '/filmManagement',
                name: 'filmManagement',
                component: () => import('@/views/filmManagement/filmManagement')
            }, {
                path: '/teleplayManagement',
                name: 'teleplayManagement',
                component: () => import('@/views/teleplayManagement/teleplayManagement')
            },
            {
                path: '/placeConfigManagement',
                name: 'placeConfigManagement',
                component: () => import('@/views/placeConfigManagement/placeConfigManagement')

            },
            {
                path: '/sportsPlaceConfigManagement',
                name: 'sportsPlaceConfigManagement',
                component: () => import('@/views/placeConfigManagement/sportsPlaceConfigManagement')

            },
            {
                path: '/sportsTypeConfigManagement',
                name: 'sportsTypeConfigManagement',
                component: () => import('@/views/typeConfigManagement/sportsTypeConfigManagement')
            },
            {
                path: '/pocketPlaceConfigManagement',
                name: 'pocketPlaceConfigManagement',
                component: () => import('@/views/placeConfigManagement/pocketPlaceConfigManagement')

            },
            {
                path: '/pocketTypeConfigManagement',
                name: 'pocketTypeConfigManagement',
                component: () => import('@/views/typeConfigManagement/pocketTypeConfigManagement')
            },
            {
                path: '/performInPubilcPlaceConfigManagement',
                name: 'performInPubilcPlaceConfigManagement',
                component: () => import('@/views/placeConfigManagement/performInPubilcPlaceConfigManagement')

            },
            {
                path: '/performInPubilcTypeConfigManagement',
                name: 'performInPubilcTypeConfigManagement',
                component: () => import('@/views/typeConfigManagement/performInPubilcTypeConfigManagement')
            },
            {
                path: '/typeConfigManagement',
                name: 'typeConfigManagement',
                component: () => import('@/views/typeConfigManagement/typeConfigManagement')
            },
            {
                path: '/storageFileManagement',
                name: 'storageFileManagement',
                component: () => import('@/views/storageFileManagement/storageFileManagement')
            },
            {
                path: '/pocketManagement',
                name: 'pocketManagement',
                component: () => import('@/views/pocketManagement/pocketManagement')

            },
            {
                path: '/sportsManagement',
                name: 'sportsManagement',
                component: () => import('@/views/sportsManagement/sportsManagement')
            },
            {
                path: '/performInPubilcManagement',
                name: 'performInPubilcManagement',
                component: () => import('@/views/performInPubilcManagement/performInPubilcManagement')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login')
    },

]
// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
})
// 利用vue的全局后置钩子，在每次页面跳转之后进行回调，实现自动返回顶部的功能
router.afterEach(() => {
    window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
    let token = store.state.token
    let noCheckPathList = store.state.noCheckPathList;
    //避免死循环，判断当前页面是不是登录页面
    let bol = noCheckPathList.find(f => {
        if (f === to.name) {
            return true
        }
    })
    if (bol !== undefined) {
        console.log('当前访问的路径无需登录，放行')
        next()
    } else if (token === '') {   //不是登录页面，就判断有没有token，如果没有就跳转登录页
        console.log('当前访问的路径需要登录，跳转登录页面')
        next({path: '/login'})
    } else if (token) {
        console.log('token校验成功，获取用户菜单')
        // getMenuList().then(resp=>{
        //     //拿到menulist路由权限---菜单
        //     store.commit("setMenuList",resp.data.data.menuList);
        //     //拿到用户操作权限
        //     store.commit("setAuthoritys",resp.data.data.authoritys);
        //     //动态绑定路由
        //     let newRoutes =router.options.routes   //获取现有的路由信息
        //     resp.data.data.menuList.forEach(menu=>{
        //         if (menu.children){
        //             menu.children.forEach(e=>{
        //                 //转成路由
        //                 let route=  menuToRoute(e)
        //                 //把路由添加到路由管理中去
        //                 if (route){
        //                     newRoutes[0].children.push(route)
        //                 }
        //             })
        //         }
        //     })
        //     router.addRoutes(newRoutes)
        //     store.commit("changeRouteStatus",hasRoute)
        //
        // })

    }
    next() //放行

})
export default router
